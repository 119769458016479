import React, { useState, FormEvent, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Jumbotron from "react-bootstrap/Jumbotron";
import Modal from "react-bootstrap/Modal";
import "./App.css";
import { Editor } from "react-draft-wysiwyg";
import * as uuid from "uuid";
import { db, storage } from "./firebase";
import { RawDraftContentState, convertToRaw, EditorState, convertFromRaw } from "draft-js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import moment from 'moment';

// Models ---------------------------------------------------------------------
// Models describe the shape of basic data that is then used to generate HTML.

interface SeedlingStoryModel {
  id?: string;
  batchId?: string;
  username?: string;
  pictures?: string[];
  content?: RawDraftContentState;
  createdOn: string;
}

interface SeedlingBatchModel {
  id?: string;
  batchName?: string;
  batchDate?: string;
}


// Seedling Story Component ---------------------------------------------------

// This "interface" contains a description of the properties expected for the
// <SeedlingStory /> component. This is *not* creating a new object, but rather
// a new type of object.
interface SeedlingStoryProps {
  story: SeedlingStoryModel;
  batches: { [batchId: string]: SeedlingBatchModel };
}

function SeedlingStory(props: SeedlingStoryProps) {
  if (!props.story.batchId) {
    return (
      <div>
        ERROR!!!!!!! PANIC!!!!

        SeedlingStory was given a story with no batchId!!!
      </div>
    );
  }

  const batch = props.batches[props.story.batchId];
  let batchName = props.story.batchId;
  if (batch && batch.batchName) {
    batchName = batch.batchName;
  }

  return (
    <Card className="w-100 h-100">
      <Card.Body>
        <Card.Title>batch: {batchName}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{props.story.username}</Card.Subtitle>
        <Carousel>
          {(props.story.pictures || []).map(pictureUrl =>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={pictureUrl}
                alt={pictureUrl}
              />
            </Carousel.Item>
          )}
        </Carousel>
        <Card.Text>
          {
            props.story.content !== undefined
              ? <Editor editorState={EditorState.createWithContent(convertFromRaw(props.story.content))} toolbarHidden readOnly />
              : null
          }
          <small><i>{ moment(props.story.createdOn).format("LLLL") }</i></small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}


// App Component --------------------------------------------------------------

function App() {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [batchId, setBatchId] = useState("");
  const [currentSeedlingBatches, setCurrentSeedlingBatches] = useState({} as { [batchId: string]: SeedlingBatchModel });
  const [currentSeedlingStories, setCurrentSeedlingStories] = useState([] as SeedlingStoryModel[]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [filesToUpload, setFilesToUpload] = useState([] as File[]);
  const [isUploading, setIsUploading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log('Render <App />');

  // Load data for the application.
  // TODO: In the future, this may need to happen when a refresh button is
  //       pressed
  const handleDataLoad = () => {
    db.collection("stories").orderBy("createdOn", "desc").limit(150).get().then(storiesQuery => {
      const firebaseStories = storiesQuery.docs.map(snapshot => snapshot.data() as SeedlingStoryModel);

      // Store it locally in <App />.
      setCurrentSeedlingStories(firebaseStories);
    });

    db.collection("batches").get().then(batchesQuery => {
      const firebaseBatches = batchesQuery.docs.map(snapshot => snapshot.data() as SeedlingBatchModel);
      const firebaseBatchLookup = {} as { [batchId: string]: SeedlingBatchModel };
      for (const batch of firebaseBatches) {
        if (batch.id) {
          firebaseBatchLookup[batch.id] = batch;
        }
      }

      // Store it locally in <App />
      setCurrentSeedlingBatches(firebaseBatchLookup);
    })

    setCurrentSeedlingStories([]);
  }
  useEffect(handleDataLoad, []);

  // for validation
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setIsUploading(true);

      try {
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        // This is where we need to write our new story to firebase.
        const newStory: SeedlingStoryModel = {
          id: uuid.v4(),
          batchId: batchId,
          username: username,
          content: convertToRaw(editorState.getCurrentContent()),
          createdOn: moment().toISOString()
        };

        // Upload images if there are any currently saved.
        const storageRef = storage.ref();
        const fileUploads = [] as Promise<string | null>[];
        for (const file of filesToUpload) {
          if (!file) {
            continue;
          }

          const filePath = newStory.id + '/' + file.name + '-' + uuid.v4();
          const fileRef = storageRef.child(filePath);
          console.log('Uploading', file.name, file);
          fileUploads.push(fileRef.put(file).then(result => result.ref.getDownloadURL()));
        }

        Promise.all(fileUploads)
          .then(fileUrls => {
            // TODO: Save uploaded file urls in newStory *before* writing it to firestore.
            const nonEmptyUrls = fileUrls.filter(url => url) as string[];
            newStory.pictures = nonEmptyUrls;
            console.log('Saving newStory', newStory, nonEmptyUrls, fileUrls);
            db.doc("stories/" + newStory.id).set(newStory).then(res => handleDataLoad());

            // Clear list of files to upload.
            setFilesToUpload([]);

            // Close modal now.
            setIsUploading(false);
            setShow(false);
          })
          .catch(err => {
            setIsUploading(false);
          });
      } catch(err) {
        setIsUploading(false);
        throw err;
      }
    }
  };

  function queuePicturesUpload(event: any) {
    const eventFiles = event.target.files as File[];

    // Queue the new files that got selected.
    setFilesToUpload([...eventFiles, ...filesToUpload]);
  }

  return (
    <div>
      <Jumbotron>
        <h1>Welcome to Seedling Stories!</h1>
        <p>Share your updates on the seedlings here. How are they growing? Are they happy?</p>
        <p>
          <Button variant="outline-success" size="lg" onClick={handleShow} disabled={isUploading}>
            Upload your pictures!
          </Button>
        </p>
      </Jumbotron>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>Add your pictures and comments below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formDisplayName">
              <Form.Label style={{ color: "green", fontWeight: "bold" }}>Display Name</Form.Label>
              <Form.Control
                placeholder="Choose what name you want everyone to see"
                required
                onInput={(e: FormEvent<HTMLInputElement>) => setUsername((e.target as HTMLInputElement).value)}
              />
            </Form.Group>
            <Form.Group controlId="formPlantCode">
              <Form.Label style={{ color: "green", fontWeight: "bold" }}>Plant Code</Form.Label>
              <Form.Control
                placeholder="Enter the code that came with your plant"
                required
                onInput={(e: FormEvent<HTMLInputElement>) => setBatchId((e.target as HTMLInputElement).value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.File style={{ color: "green", fontWeight: "bold" }} id="exampleFormControlFile1" onChange={queuePicturesUpload} label="Upload Picture" />
              {/* <FormControl name="images[]" type="file" multiple onChange={this.handlePhotos}/> */}
            </Form.Group>
            <div>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </div>
            <Button className="mr-3" variant="secondary" onClick={handleClose} disabled={isUploading}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isUploading}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Container fluid>
        <Row>
          {currentSeedlingStories.map(story => <Col xs={12} sm={6} md={4} lg={3} key={story.id} className="pb-3 pt-2"><SeedlingStory story={story} batches={currentSeedlingBatches} /></Col>)}
        </Row>
      </Container>
    </div>
  );
}

export default App;
