import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBXnMYH2cq0cbNKL4xH5JBxG7TXaZrP0C8",
  authDomain: "seedling-stories.firebaseapp.com",
  databaseURL: "https://seedling-stories.firebaseio.com",
  projectId: "seedling-stories",
  storageBucket: "seedling-stories.appspot.com",
  messagingSenderId: "815140019942",
  appId: "1:815140019942:web:e018861d1c64b7c17f2527",
  measurementId: "G-DVZ49RG692"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();

analytics.setAnalyticsCollectionEnabled(true);
